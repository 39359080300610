import { useTranslation } from 'next-i18next';
import Modal from 'react-bootstrap/Modal';
import InvoiceSteps from './InvoiceSteps';

type SubmitInvoiceModalProps = { show: boolean; invoice?: any; onClose: () => void };

export default function SubmitInvoiceModal({ show, invoice, onClose: handleClose }: SubmitInvoiceModalProps) {
  const { t } = useTranslation('invoices');

  return (
    <Modal show={show} onHide={handleClose} className="submit-invoice-modal" centered size="lg">
      <Modal.Header className="py-1 border-0 text-center">
        <h1 className="h2 w-100 mt-4 mb-3">{t('invoiceSubmitted.title')}</h1>
      </Modal.Header>

      <Modal.Body className="pt-0 text-center">
        <InvoiceSteps invoice={invoice} />
        {t('invoiceSubmitted.message')
          .split('<br/>')
          .map((x, index) => (
            <p key={index as number}>{x}</p>
          ))}
      </Modal.Body>
      <Modal.Footer className="pt-0 border-0">
        <div className="SubmitInvoice-buttons text-end mt-2">
          <button type="button" className="btn btn-primary ms-1" onClick={() => handleClose()}>
            {t('invoiceSubmitted.closeButton')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
