import { Parser } from '@factofly/formatting';
import useUser from '~/contexts/User/useUser';

const useNumberParser = () => {
  const { locale } = useUser();
  /**
   * Transforms numbers used in validation
   * @param input input if available
   * @param value input of number if not already transformed
   * @returns Number in correct format
   */
  function transformNumber(input: number, value: string) {
    if (Number.isNaN(input) && value === '') return null;
    if (Number.isNaN(input) && value) return Parser.parseNumber(value, locale);
    return input;
  }

  /**
   * Converts number to format based on user's locale
   * @param value value to be converted
   * @returns value in user's locale number format
   */
  function parseNumber(value: string | number | undefined) {
    if (!value) return null;
    if (typeof value === 'number') return value;
    return Parser.parseNumber(value, locale);
  }

  return {
    transformNumber,
    parseNumber
  };
};

export default useNumberParser;
