import { InvoiceStatusEnum, SalaryPaymentTypeEnum } from '~/.generated/globalTypes';
import { InvoiceDetailsQuery_invoice as InvoiceType } from '../../InvoiceDetails/graphql/.generated/InvoiceDetailsQuery';

const trKey = 'invoiceSubmitted.nextStepsMessages.';
const oneHourInTime = 3600000;
const oneDayInTime = oneHourInTime * 24;

const InvoiceStatusMessage = {
  sentMonThursBetweenMidnightAnd4pm: `${trKey}invoiceSent.sentMonThursBetweenMidnightAnd4pm`,
  sentFridayAfter4pmUntilSundayat: `${trKey}invoiceSent.sentFridayAfter4pmUntilSundayat`,
  delayed: `${trKey}invoiceSent.delayed`,

  chosenInvoiceDateIsTheSameDateAsToday: `${trKey}invoicePending.chosenInvoiceDateIsTheSameDateAsToday`,
  chosenInvoiceDateIsNotTheSameDay: `${trKey}invoicePending.chosenInvoiceDateIsNotTheSameDay`,

  invoiceHasBeenSentToYourCustomer: `${trKey}invoiceSenttoYourCustomer.invoiceHasBeenSentToYourCustomer`,
  invoiceExceedsPaymentDeadline: `${trKey}invoiceSenttoYourCustomer.invoiceExceedsPaymentDeadline`,

  invoiceSentAwaitingApproval: `${trKey}invoiceSentAwaitingApproval`,

  bulkPayment: `${trKey}invoicePaidApprovedByCompany.bulkPayment`,
  earlyPaymentEarly: `${trKey}invoicePaidApprovedByCompany.earlyPaymentEarly`,
  earlyPaymentLate: `${trKey}invoicePaidApprovedByCompany.earlyPaymentLate`,
  standardPaymentEarly: `${trKey}invoicePaidApprovedByCompany.standardPaymentEarly`,
  standardPaymentLate: `${trKey}invoicePaidApprovedByCompany.standardPaymentLate`,

  salaryPaid: `${trKey}salaryPaid`
};

type DefineInvoiceStepMessagesProps = {
  invoice: Partial<InvoiceType>;
  nowTime: Date;
  salaryPaymentType?: any;
};

type DefineInvoiceStepMessagesReturnType = {
  message?: string;
  time?: {
    [key: string]: any;
  };
};
const sentMondayThursdayCase = ({
  toDay,
  toDayTime,
  targetYear,
  targetMonth,
  targetDate,
  targetTime,
  startTime
}: any): DefineInvoiceStepMessagesReturnType => {
  const middleTime = new Date(targetYear, targetMonth, targetDate, 16).getTime();
  const endTime = startTime + oneDayInTime;
  const isFirstInterval = targetTime > startTime && targetTime < middleTime;
  const isValid = isFirstInterval ? toDayTime < middleTime + oneHourInTime : toDayTime < endTime;

  if (!isValid) {
    return {
      message: InvoiceStatusMessage.delayed
    };
  }

  return {
    message: InvoiceStatusMessage.sentMonThursBetweenMidnightAnd4pm,
    time: {
      time: (isFirstInterval ? 17 : 24) - toDay.getHours()
    }
  };
};

const sentFridayCase = ({
  toDay,
  toDayTime,
  targetYear,
  targetMonth,
  targetDate,
  targetTime,
  startTime
}: any): DefineInvoiceStepMessagesReturnType => {
  let middleTime: Date | number = new Date(targetYear, targetMonth, targetDate, 15);

  if (middleTime.getDay() !== 5) {
    const currentDay = middleTime.getDay() || 7;
    const fridayDate = targetDate - (currentDay - 5);
    middleTime = new Date(targetYear, targetMonth, fridayDate, 15).getTime();
  } else {
    middleTime = middleTime.getTime();
  }

  const endTime = startTime + 3 * oneDayInTime;
  const isFirstInterval = targetTime > startTime && targetTime < middleTime;
  const isValid = isFirstInterval ? toDayTime < middleTime + oneHourInTime : toDayTime < endTime;

  if (!isValid) {
    return {
      message: InvoiceStatusMessage.delayed
    };
  }

  if (isFirstInterval) {
    return {
      message: InvoiceStatusMessage.sentMonThursBetweenMidnightAnd4pm,
      time: { time: 16 - toDay.getHours() }
    };
  }
  return {
    message: InvoiceStatusMessage.sentFridayAfter4pmUntilSundayat
  };
};

const sendPendingMessage = (date: Date, nowTime: Date): DefineInvoiceStepMessagesReturnType => {
  const today = nowTime;

  const todayStartTime = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
  const targetStartTime = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();

  const isToday = todayStartTime === targetStartTime;

  if (isToday) {
    return {
      message: InvoiceStatusMessage.chosenInvoiceDateIsTheSameDateAsToday,
      time: { time: 18 - today.getHours() }
    };
  }

  return {
    message: InvoiceStatusMessage.chosenInvoiceDateIsNotTheSameDay,
    time: {
      time: date.toLocaleDateString('en-GB')
    }
  };
};

const sentToCompany = (date: Date, nowTime: Date): DefineInvoiceStepMessagesReturnType => {
  const today = nowTime;
  const endTime = date.getTime() + 7 * oneDayInTime;

  const currentTime = today.getTime();
  if (currentTime < endTime) {
    const countDown = Math.floor((endTime - currentTime) / oneDayInTime);
    return {
      message: InvoiceStatusMessage.invoiceHasBeenSentToYourCustomer,
      time: { time: countDown || 'Today' }
    };
  }

  const countDown = Math.floor((currentTime - endTime) / oneDayInTime);
  const firstRemainderEndTime = endTime + 4 * oneDayInTime;
  const secondRemainderEndTime = endTime + 7 * oneDayInTime;

  if (currentTime < firstRemainderEndTime) {
    const RemainderCountDown = Math.floor((firstRemainderEndTime - currentTime) / oneDayInTime);
    return {
      message: InvoiceStatusMessage.invoiceExceedsPaymentDeadline,
      time: {
        time: countDown || 'Today',
        reminderDay: RemainderCountDown
      }
    };
  }

  if (currentTime < secondRemainderEndTime) {
    const RemainderCountDown = Math.floor((secondRemainderEndTime - currentTime) / oneDayInTime);
    return {
      message: InvoiceStatusMessage.invoiceExceedsPaymentDeadline,
      time: {
        hour: countDown || 'Today',
        reminderDay: RemainderCountDown
      }
    };
  }

  return {
    message: InvoiceStatusMessage.invoiceExceedsPaymentDeadline,
    time: {
      hour: countDown || 'Today',
      reminderDay: '0'
    }
  };
};

const defineInvoiceStepMessages = ({
  invoice,
  salaryPaymentType,
  nowTime
}: DefineInvoiceStepMessagesProps): DefineInvoiceStepMessagesReturnType => {
  const createdDate = new Date(invoice?.createdDate);
  const todaysDate = nowTime;
  const targetYear = createdDate.getFullYear();
  const targetMonth = createdDate.getMonth();
  const targetDate = createdDate.getDate();
  const toDayTime = todaysDate.getTime();
  const targetTime = createdDate.getTime();
  const startTime = new Date(targetYear, targetMonth, targetDate).getTime();
  const createdDateWeekDay = createdDate.getDay();
  const customerPaymentDate = invoice?.customerPaymentDate;
  const timeOptions = {
    toDay: todaysDate,
    toDayTime,
    targetYear,
    targetMonth,
    targetDate,
    targetTime,
    startTime
  };

  switch (invoice?.status) {
    case InvoiceStatusEnum.DRAFT:
      return { message: undefined };
    case InvoiceStatusEnum.SENT: {
      // invoice sent A and B
      if (createdDateWeekDay > 0 && createdDateWeekDay < 5) {
        return sentMondayThursdayCase(timeOptions);
      }
      if ([5, 6, 0].includes(createdDateWeekDay)) {
        return sentFridayCase(timeOptions);
      }
      break;
    }
    case InvoiceStatusEnum.PENDING: {
      return sendPendingMessage(createdDate, nowTime);
    }
    case InvoiceStatusEnum.SENT_TO_COMPANY: {
      return sentToCompany(createdDate, nowTime);
    }
    case InvoiceStatusEnum.SENT_TO_COMPANY_AWAITING_APPROVAL: {
      if (invoice.earlyPayment) {
        return { message: InvoiceStatusMessage.invoiceSentAwaitingApproval };
      }
      break;
    }
    case InvoiceStatusEnum.PAID || InvoiceStatusEnum.APPROVED_BY_COMPANY: {
      if (
        salaryPaymentType &&
        [SalaryPaymentTypeEnum.TIMED, SalaryPaymentTypeEnum.VALUE].includes(salaryPaymentType as any)
      ) {
        return { message: InvoiceStatusMessage.bulkPayment };
      }
      const customerPaymentByHours = new Date(customerPaymentDate).getHours();
      if (invoice.earlyPayment && customerPaymentByHours < 12) {
        return { message: InvoiceStatusMessage.earlyPaymentEarly, time: { hours: 18 - nowTime.getHours() } };
      }
      if (invoice.earlyPayment && customerPaymentByHours >= 12) {
        return { message: InvoiceStatusMessage.earlyPaymentLate };
      }
      if (!invoice.earlyPayment && customerPaymentByHours < 12) {
        return {
          message: InvoiceStatusMessage.standardPaymentEarly,
          time: { hours: 18 - nowTime.getHours() }
        };
      }
      if (!invoice.earlyPayment && customerPaymentByHours >= 12) {
        return { message: InvoiceStatusMessage.standardPaymentLate };
      }
      break;
    }
    case InvoiceStatusEnum.CANCELLED || InvoiceStatusEnum.DENIED_BY_COMPANY: {
      return { message: undefined };
    }
    case InvoiceStatusEnum.COMPANY_DISPUTE: {
      return { message: undefined };
    }
    case InvoiceStatusEnum.SALARY_PAID || InvoiceStatusEnum.SALARY_PAID_CUSTOMER_PAID: {
      return { message: InvoiceStatusMessage.salaryPaid };
    }
    default:
      return { message: undefined };
  }
  return { message: undefined };
};

export default defineInvoiceStepMessages;
