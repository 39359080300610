export default class Parser {
    static extractLocaleRegex(locale) {
        const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
        const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
        const index = new Map(numerals.map((digit, index) => [digit, index.toString()]));
        const groupCharacter = parts.find((numberFormatPart) => numberFormatPart.type === 'group').value;
        const decimalCharacter = parts.find((numberFormatPart) => numberFormatPart.type === 'decimal').value;
        const groupRegex = new RegExp(`[${groupCharacter}]`, 'g');
        const decimalRegex = new RegExp(`[${decimalCharacter}]`);
        const numeralRegex = new RegExp(`[${numerals.join('')}]`, 'g');
        const indexReplacer = (digit) => index.get(digit);
        return {
            groupRegex,
            decimalRegex,
            numeralRegex,
            indexReplacer
        };
    }
    static parseNumber(string, locale) {
        const { groupRegex, decimalRegex, numeralRegex, indexReplacer } = this.extractLocaleRegex(locale);
        const substitutedString = string
            .trim()
            .replace(groupRegex, '')
            .replace(decimalRegex, '.')
            .replace(numeralRegex, indexReplacer);
        return Number.isNaN(Number(substitutedString)) ? Number.NaN : Number(substitutedString);
    }
}
