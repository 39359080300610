// export type defineInvoiceStepProps = {

import { InvoiceStatusEnum } from '~/.generated/globalTypes';

export const positions = new Map([
  [InvoiceStatusEnum.DRAFT, 1],
  [InvoiceStatusEnum.SENT, 2],

  [InvoiceStatusEnum.PENDING, 2],
  [InvoiceStatusEnum.MORE_INFO_NEEDED, 2],

  [InvoiceStatusEnum.SENT_TO_COMPANY, 3],
  [InvoiceStatusEnum.SENT_TO_COMPANY_NEEDS_CONTRACT, 3],
  [InvoiceStatusEnum.SENT_TO_COMPANY_CONTRACT_MADE, 3],
  [InvoiceStatusEnum.SENT_TO_COMPANY_AWAITING_APPROVAL, 3],
  [InvoiceStatusEnum.APPROVED_BY_COMPANY, 3],
  [InvoiceStatusEnum.LATE_PAYMENT, 3],
  [InvoiceStatusEnum.DEBT_COLLECTION, 3],
  [InvoiceStatusEnum.COMPANY_DISPUTE, 3],
  [InvoiceStatusEnum.PAYMENT_ON_HOLD, 3],

  // [InvoiceStatusEnum.CANCELLED, 5],
  // [InvoiceStatusEnum.DENIED_BY_COMPANY, 5],

  [InvoiceStatusEnum.PAID, 4],
  [InvoiceStatusEnum.PAYMENT_RECEIVED, 4],
  [InvoiceStatusEnum.SALARY_PAID, 6],
  [InvoiceStatusEnum.SALARY_PAID_CUSTOMER_PAID, 6],
  [InvoiceStatusEnum.SALARY_PAID_CUSTOMER_NOT_PAID, 6]
]);

const defineInvoiceStep = ([ref0, ref1, ref2, ref3, ref4]: any, status?: InvoiceStatusEnum) => {
  const position = positions.get(status!) ?? 2;
  const steps = [
    {
      title: 'invoiceSubmitted.nextSteps.submitInvoice',
      completed: position > 0,
      indicatorRef: ref0,
      active: position === 1
    },
    {
      title: 'invoiceSubmitted.nextSteps.queuedWithAccounting',
      completed: position > 1,
      indicatorRef: ref1,
      active: position === 2
    },
    {
      title: 'invoiceSubmitted.nextSteps.sentToClient',
      completed: position > 2,
      indicatorRef: ref2,
      active: position === 3
    },
    {
      title: 'invoiceSubmitted.nextSteps.clientPaid',
      completed: position > 3,
      indicatorRef: ref3,
      active: position === 4
    },
    {
      title: 'invoiceSubmitted.nextSteps.salaryPaid',
      completed: position > 4,
      indicatorRef: ref4,
      active: position === 5
    }
  ];
  return steps;
};

export default defineInvoiceStep;
