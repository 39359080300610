import { useTranslation } from 'next-i18next';

export default function LoadingComponent() {
  const { t } = useTranslation('common');

  return (
    <div className="loading">
      <span className="loading-text d-flex align-items-center">
        <div className="spinner-border text-primary me-3" role="status" />
        {`${t('messages.loading')}...`}
      </span>
      <style jsx>{`
        .loading {
          text-align: center;
          min-height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .loading .loading-text {
          font-size: 1.5rem;
          font-weight: bold;
        }
      `}</style>
    </div>
  );
}
