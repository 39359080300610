import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useRef } from 'react';
import useWindowDimensions from '~/features/shared/hooks/useWindowDimensions';
import { InvoiceDetailsQuery_invoice as InvoiceType } from '../InvoiceDetails/graphql/.generated/InvoiceDetailsQuery';
import defineInvoiceStep from './utils/defineInvoiceStep';
import defineInvoiceStepMessages from './utils/defineInvoiceStepMessages';
import useUser from '~/contexts/User/useUser';
import useFeatures from '~/features/shared/hooks/useFeatures';
import { MOBILE_BREAKPOINT } from '~/features/shared/constants';

export type StepType = {
  title: string;
  completed: boolean;
  indicatorRef: any;
  active?: boolean;
};

export type InvoiceStepsProps = {
  invoice?: InvoiceType;
};

export default function InvoiceSteps({ invoice }: InvoiceStepsProps) {
  const { t } = useTranslation('invoices');

  const { width, height } = useWindowDimensions();
  const { salaryPaymentType } = useUser();
  const { isEnabled } = useFeatures();
  const isMobile = width < MOBILE_BREAKPOINT;

  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const steps: StepType[] = useMemo(
    () => defineInvoiceStep([ref0, ref1, ref2, ref3, ref4], invoice?.status),
    [invoice]
  );

  const indicatorWidth = isMobile ? 30 : 40;
  const canvasWidth = isMobile ? width - 20 : 840;
  const isHorizontal = !isMobile;

  useEffect(() => {
    const c = document.getElementById('timeline-canvas') as HTMLCanvasElement;
    const ctx = c.getContext('2d');

    if (!ctx) return;

    const indicatorOffset = indicatorWidth / 2;

    const canvasBounds = c.getBoundingClientRect();

    const rects = steps
      .map((x) => (x.indicatorRef!.current! as HTMLElement).getBoundingClientRect())
      .map(({ x, y }) => ({
        x: x - canvasBounds.x + indicatorOffset,
        y: y - canvasBounds.y + indicatorOffset
      }));

    ctx.clearRect(0, 0, c.width, c.height);
    ctx.lineWidth = 5;

    ctx.beginPath();

    const verticalOffset = isHorizontal ? 0 : indicatorOffset;
    const horizontalOffset = isHorizontal ? indicatorOffset : 0;

    let startHorizontalOffset = isHorizontal ? horizontalOffset - 2 : 0;
    rects.forEach((rect, i) => {
      if (i < rects.length - 1 && steps[i].completed && (steps[i + 1].completed || steps[i + 1].active)) {
        ctx.strokeStyle = '#5cb85c';
        ctx.lineWidth = 8;
        ctx.moveTo(rect.x + startHorizontalOffset, rect.y + verticalOffset);
        ctx.lineTo(rects[i + 1].x - horizontalOffset, rects[i + 1].y - verticalOffset);
        ctx.stroke();
      }
    });

    ctx.beginPath();

    startHorizontalOffset = isHorizontal ? horizontalOffset : 0;
    rects.forEach((rect, i) => {
      if (i < rects.length - 1 && (!steps[i].completed || !(steps[i + 1].completed || steps[i + 1].active))) {
        ctx.strokeStyle = '#eee';
        ctx.moveTo(rect.x + startHorizontalOffset, rect.y + verticalOffset);
        ctx.lineTo(rects[i + 1].x - horizontalOffset, rects[i + 1].y - verticalOffset);
        ctx.stroke();
      }
    });
  }, [steps, width, height, indicatorWidth, isHorizontal]);

  const invoiceDataForMessage = {
    createdDate: invoice?.createdDate,
    customerPaymentDate: invoice?.customerPaymentDate,
    status: invoice?.status,
    earlyPayment: invoice?.earlyPayment
  };
  const nowTime = new Date();

  const stepMessage = defineInvoiceStepMessages({
    invoice: invoiceDataForMessage,
    salaryPaymentType,
    nowTime
  });

  const translatedStepMessage =
    stepMessage.message && isEnabled('feature-invoice-status-message')
      ? t(stepMessage.message, stepMessage.time)
      : '';

  return (
    <div className="invoice-steps mt-4">
      <div className="pt-0 text-center">
        <div className="next-steps">
          <canvas
            id="timeline-canvas"
            style={{ position: 'absolute', zIndex: 1 }}
            width={canvasWidth}
            height={isHorizontal ? '80' : '300'}
          />
          <div className="row">
            {steps.map((step: StepType, i) => (
              <div className="col-sm" key={step.title}>
                <div className="row">
                  <div
                    className={classNames('col-4 col-sm-12', {
                      'text-end': isMobile
                    })}
                  >
                    <div
                      ref={step.indicatorRef}
                      className={classNames('step-indicator  mt-2 text-center rounded-circle', {
                        'bg-success ': step.completed,
                        'bg-light not-active-step-number': !step.completed,
                        active: step.active
                      })}
                    >
                      <span className={classNames(' text-center rounded-circle')}>{i + 1}</span>
                    </div>
                  </div>
                  <div
                    className={classNames('col-8 col-sm-12 mt-2', {
                      'text-center': !isMobile,
                      'text-start': isMobile
                    })}
                  >
                    {t(step.title)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="message">
          <p className="mt-3 p-0 text-center">{translatedStepMessage}</p>
          <style global jsx>{`
            .text-justify {
              text-align: justify;
            }
            .invoice-steps .step-indicator {
              width: ${indicatorWidth}px;
              height: ${indicatorWidth}px;
              border: 1px solid #eee;
              display: flex;
              justify-content: center;
              align-content: center;
              flex-direction: column;
              font-size: 1.2rem;
              font-weight: bold;
              margin: 0 0 0 auto;
              color: white;
            }
            .not-active-step-number {
              color: #274861 !important;
              border: 2px solid #dadee1 !important;
            }

            .invoice-steps .step-indicator.active {
              border: 2px solid #0142c2 !important;
              background-color: #0142c2 !important;
            }
            .invoice-steps #timeline-canvas {
              position: absolute;
              left: 0;
              top: 0;
            }

            @media (min-width: ${MOBILE_BREAKPOINT}px) {
              .invoice-steps .step-indicator {
                margin: 0 auto;
              }
            }
          `}</style>
        </div>
      </div>
    </div>
  );
}
